import Picture from './components/picture_gallery'


   
    function importAll(r) {
        let images = [];
        r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
        return images;
      }

     
    
      function makeArray(area) {
        let kuvat =[]
        let x = 0
        let images = []
        if(area === 'nizza'){
            images = importAll(require.context("./assets/nizza", false, /\.(png|jpe?g|svg)$/))
        }
        if(area === 'monaco'){
            images = importAll(require.context("./assets/monaco", false, /\.(png|jpe?g|svg)$/))
        }
        if(area === 'narbonne'){
            images = importAll(require.context("./assets/narbonne", false, /\.(png|jpe?g|svg)$/))
        }
        if(area === 'costabrava'){
            images = importAll(require.context("./assets/costabrava", false, /\.(png|jpe?g|svg)$/))
        }
        if(area === 'barcelona'){
            images = importAll(require.context("./assets/barcelona", false, /\.(png|jpe?g|svg)$/))
        }
        for(let i = 1;i<=100;i++, x++){
            kuvat[x] = images[`${area} (${i}).jpg`]
            
            if(kuvat[x] === undefined){
                kuvat.pop()
                kuvat = createGroups(kuvat,4)
                break;
            }
        }
        return kuvat
      }

      function createGroups(arr, numGroups) {
        const perGroup = Math.ceil(arr.length / numGroups);
        return new Array(numGroups)
          .fill(undefined)
          .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
      }

      
     

export const Nizza = (props) =>{
    const nizza = makeArray('nizza')
    return(
        <div className='container'>

            <div className='column'>

            {nizza[0].map((nizza,index)=>{
                return <Picture key={index} src={nizza} alt={"nizza"}/>
            })}

            </div>

            <div className='column'>

            {nizza[1].map((nizza,index)=>{
                return <Picture key={index} src={nizza} alt={"nizza"}/>
            })}

            </div>

            <div className='column'>

            {nizza[2].map((nizza,index)=>{
                return <Picture key={index} src={nizza} alt={"nizza"}/>
            })}
            
            </div>
             <div className='column'>

            {nizza[3].map((nizza,index)=>{
                return <Picture key={index} src={nizza} alt={"nizza"}/>
            })}
            
            </div>
        </div>
    )
}

export const Monaco = (props) =>{
    const monaco = makeArray('monaco')
    return(
        <div className='container'>

            <div className='column'>

            {monaco[0].map((monaco,index)=>{
                return <Picture key={index} src={monaco} alt={"monaco"}/>
            })}

            </div>

            <div className='column'>

            {monaco[1].map((monaco,index)=>{
                return <Picture key={index} src={monaco} alt={"monaco"}/>
            })}

            </div>

            <div className='column'>

            {monaco[2].map((monaco,index)=>{
                return <Picture key={index} src={monaco} alt={"monaco"}/>
            })}
            
            </div>
             <div className='column'>

            {monaco[3].map((monaco,index)=>{
                return <Picture key={index} src={monaco} alt={"monaco"}/>
            })}
            
            </div>
        </div>
    )
}

export const Narbonne = (props) =>{
    const narbonne = makeArray('narbonne')
    return(
        <div className='container'>

            <div className='column'>

            {narbonne[0].map((narbonne,index)=>{
                return <Picture key={index} src={narbonne} alt={"narbonne"}/>
            })}

            </div>

            <div className='column'>

            {narbonne[1].map((narbonne,index)=>{
                return <Picture key={index} src={narbonne} alt={"narbonne"}/>
            })}

            </div>

            <div className='column'>

            {narbonne[2].map((narbonne,index)=>{
                return <Picture key={index} src={narbonne} alt={"narbonne"}/>
            })}
            
            </div>
             <div className='column'>

            {narbonne[3].map((narbonne,index)=>{
                return <Picture key={index} src={narbonne} alt={"narbonne"}/>
            })}
            
            </div>
        </div>
    )
}

export const CostaBrava = (props) =>{
    const costabrava = makeArray('costabrava')
    return(
        <div className='container'>

            <div className='column'>

            {costabrava[0].map((costabrava,index)=>{
                return <Picture key={index} src={costabrava} alt={"costabrava"}/>
            })}

            </div>

            <div className='column'>

            {costabrava[1].map((costabrava,index)=>{
                return <Picture key={index} src={costabrava} alt={"costabrava"}/>
            })}

            </div>

            <div className='column'>

            {costabrava[2].map((costabrava,index)=>{
                return <Picture key={index} src={costabrava} alt={"costabrava"}/>
            })}
            
            </div>
             <div className='column'>

            {costabrava[3].map((costabrava,index)=>{
                return <Picture key={index} src={costabrava} alt={"costabrava"}/>
            })}
            
            </div>
        </div>
    )
}

export const Barcelona = (props) =>{
    const barcelona = makeArray('barcelona')
    return(
        <div className='container'>

            <div className='column'>

            {barcelona[0].map((barcelona,index)=>{
                return <Picture key={index} src={barcelona} alt={"barcelona"}/>
            })}

            </div>

            <div className='column'>

            {barcelona[1].map((barcelona,index)=>{
                return <Picture key={index} src={barcelona} alt={"barcelona"}/>
            })}

            </div>

            <div className='column'>

            {barcelona[2].map((barcelona,index)=>{
                return <Picture key={index} src={barcelona} alt={"barcelona"}/>
            })}
            
            </div>
             <div className='column'>

            {barcelona[3].map((barcelona,index)=>{
                return <Picture key={index} src={barcelona} alt={"barcelona"}/>
            })}
            
            </div>
        </div>
    )
}





