import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Monaco, Nizza, Narbonne, CostaBrava, Barcelona} from "./gallery";
import './gallery.css'


ReactDOM.createRoot(document.getElementById('root')).render(
<BrowserRouter>
<Routes>
    <Route path="/" element={<App />} />
    <Route path="/nice" element={<Nizza/>} />
    <Route path="/monaco" element={<Monaco/>} />
    <Route path="/narbonne" element={<Narbonne/>} />
    <Route path="/costabrava" element={<CostaBrava/>} />
    <Route path="/barcelona" element={<Barcelona/>} />
</Routes>

</BrowserRouter>)
