
import { Link} from 'react-router-dom'

function App() {
  return (
    <div>
      <Link to="/nice">
      <div className='linkBox'>
      <h1>Nicé</h1>
      </div>
      </Link>

      <Link to="/monaco">
      <div className='linkBox'>
      <h1>Monaco</h1>
      </div>
      </Link>

      <Link to="/narbonne">
      <div className='linkBox'>
      <h1>Narbonne</h1>
      </div>
      </Link>

      <Link to="/costabrava">
      <div className='linkBox'>
      <h1>Costa Brava</h1>
      </div>
      </Link>

      <Link to="/barcelona">
      <div className='linkBox'>
      <h1>Barcelona</h1>
      </div>
      </Link>
    </div>
  );
}

export default App;
